import { produce } from "immer";
import { createSelector } from "reselect";


const initialState = {
    jobs: null
};

const reducer = (state = initialState, action) => produce(state, draft => {
    switch (action.type) {
        case "SET_JOBS":
            draft.jobs = action.payload.jobs;
            break;
    }
    return draft;
});

export default reducer;

export const reloadJobs = () => ({ type: "SET_JOBS", payload: { jobs: null } });

export const setJobs = (jobs) => ({ type: "SET_JOBS", payload: { jobs } });

const selectState = (state) => state.farms;

export const selectJobs = createSelector(selectState, (state) => state.jobs);
