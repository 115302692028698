import { useSelector } from "react-redux";
import { useAuthContext } from "@agricircle/shared";
import { useLoadFarms } from "@agricircle/shared/farms/hooks";
import { selectMode } from "../redux/soil";
import { useLoadSampling } from "./sampling";

export const loadSoil = () => {
    const { profile } = useAuthContext();
    const mode = useSelector(selectMode);

    useLoadFarms(profile?.id, mode === "edit", { extraAttributes: ["soil", "map_metadata"] });
    //useLoadSampling(profile?.id);
};
