import { useSelector } from "react-redux";
import { selectFarms, selectFields } from "@agricircle/shared/farms/redux";
import { InitialFarmSetup } from "@agricircle/shared/farms/components";
import FarmInspect from "./FarmInspect";
import FarmEdit from "./FarmEdit";
import { selectMode } from "../../redux/soil";


export default () => {
    const mode = useSelector(selectMode);
    const farms = useSelector(selectFarms);
    const fields = useSelector(selectFields);

    if (farms == null) return null;

    if (farms.length == 0) return <InitialFarmSetup />;

    if (fields == null) return null;

    return mode === "edit" ? <FarmEdit /> : <FarmInspect />;
};
