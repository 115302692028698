import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { List } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/NavigateNext";
import { useAuthContext } from "@agricircle/shared";
import { selectFarm } from "@agricircle/shared/farms/redux";
import { selectJobs } from "../../redux/sampling";



export const SamplingJob = () => {
    const { profile } = useAuthContext();
    const { jobId } = useParams();
    const jobs = useSelector(selectJobs);

    if (!jobs) return null;
    const job = jobs.find(j => j.id === jobId);
    if (!job) return "Job not found";

    if (profile.organization.type === "sampler")
        return <SamplerJob job={job} />
    if (profile.organization.type === "lab")
        return <LabJob job={job} />
    return <AdvisorJob job={job} />
};

export const SamplingJobs = () => {
    const farm = useSelector(selectFarm);
    const jobs = useSelector(selectJobs);

    if (!farm || !jobs) return null;

    if (!jobs.length) return "No jobs yet";

    return (<List>
        {jobs.map(job => (<ListItem secondaryAction={<IconButton href={`job/${job.id}`}><ArrowRightIcon /></IconButton>}>
            <ListItemText primary={job.updated_at} secondary={job.status} />
        </ListItem>))}
    </List>);
};
